<template>
  <div>
    <gl-title title="评价管理"></gl-title>
    <gl-page-header>
      <el-form :model="mFormData" ref="listForm" :inline="true" size="mini">
        <el-form-item prop="userName" label="会员昵称">
          <gl-list-search v-model="mFormData.userName" placeholder="会员昵称"></gl-list-search>
        </el-form-item>
        <el-form-item prop="goodsTitle" label="商品名称">
          <gl-list-search v-model="mFormData.goodsTitle" placeholder="商品名称"></gl-list-search>
        </el-form-item>
        <el-form-item prop="status" label="审核状态">
          <gl-option v-model="mFormData.status" :list="statusList"></gl-option>
        </el-form-item>
        <el-form-item prop="startTime" label="发布时间">
          <gl-date-picker :startTime.sync="mFormData.startTime" :endTime.sync="mFormData.endTime"></gl-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button  type="primary" icon="el-icon-search" @click="mReload"> 搜索 </el-button>
          <el-button icon="el-icon-refresh" @click="mResetForm('listForm')"> 重置 </el-button>
        </el-form-item>
      </el-form>
    </gl-page-header>
    <gl-card v-loading="mLoading">
      <el-table :data="mTableData" style="width: 100%">
        <el-table-column type="index" :index="mIndexMethod" label="序号" align="center" min-width="30"></el-table-column>
        <el-table-column prop="userName" label="会员昵称" width="100"></el-table-column>
        <el-table-column prop="title" label="商品"></el-table-column>
        <el-table-column prop="title" label="内容预览"></el-table-column>
        <el-table-column prop="title" label="发布时间"></el-table-column>
        <el-table-column prop="name" label="审批状态">
          <template slot-scope="scope">
            <span v-if="scope.row.status == 1">待审核</span>
            <span v-if="scope.row.status == 2">已通过</span>
            <span >不通过</span>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="操作" width="180px" fixed="right">
          <template slot-scope="scope">
            <el-button type="text" @click="mClickAction('approveVisible',scope.row.id)"> 审核 </el-button>
            <el-button type="text" @click="mClickAction('mUpdateVisible',scope.row.id)"> 编辑 </el-button>
            <el-button type="text" @click="mDelete(scope.row.id)"> 删除 </el-button>
            <el-button type="text" @click="mClickAction('mPreviewVisible', scope.row.id)"> 查看 </el-button>
          </template>
        </el-table-column>
      </el-table>
      <gl-pagination
        :total="mTotal"
        :current-page="mPageNum"
        @currentChange="mHandleCurrentChange"
        @sizeChange="mHandleSizeChange">
      </gl-pagination>
    </gl-card>
    <update :visible.sync="mUpdateVisible" :itemId="mItemId"/>
    <preview :visible.sync="mPreviewVisible" :itemId="mItemId"/>
    <approve :visible.sync="approveVisible" :itemId="mItemId"/>
  </div>
</template>

<script>
import ListMixin from '@/mixins/list.mixin.js'
import update from './update.vue'
import preview from './preview.vue'
import approve from './approve.vue'
export default {
  components: {update,preview,approve},
  mixins: [ListMixin],
  data(){
    return{
      approveVisible: false,
      statusList: [
        {name: '待审核', value: '1'},
        {name: '已通过', value: '2'},
        {name: '不通过', value: '3'},
      ],
    }
  },
  mounted(){
    this.mGetListFun 
    this.mDeleteFun
    this.mGetList()
  },
}
</script>

<style>

</style>