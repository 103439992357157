<template>
  <el-dialog 
    title="详情"
    :visible="visible"
    :close-on-click-modal="true"
    :before-close="handleClose"
    width="600px"
  >
    <el-form :model="formData" ref="form" label-width="120px" size="mini" v-loading="loading">
      <el-form-item prop="userName" label="会员昵称">
        <div> {{formData.userName}} </div>
      </el-form-item>
      <el-form-item prop="goodsName" label="评价商品">
        <div> {{formData.goodsName}} </div>
      </el-form-item>
      <el-form-item prop="content" label="评价内容">
        <div> {{formData.goodsName}} </div>
      </el-form-item>
      <el-form-item prop="contentImages" label="评价图片">
        <div style="display:flex;">
          <div v-for="(item,index) of formData.contentImages" :key="index" style="margin-right:10px;margin-bottom:10px;">
            <el-image
              style="width:100px;height:100px;"
              :src="item"
              fit="cover"
              :preview-src-list="formData.contentImages"
            />
          </div>
        </div>
      </el-form-item>
      <el-form-item prop="approve" label="审核">
        <div> {{formData.approve | filterStatus}} </div>
      </el-form-item>
    </el-form> 
    <gl-flex>
      <el-button @click="handleClose" >关闭</el-button>
    </gl-flex>
  </el-dialog>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    itemId: {
      type: String,
      default: '',
    },
  },
  data(){
    return{
      formData: {},
      loading: false,
    }
  },
  watch: {
    visible: {
      handler: function(newVal){
        newVal && this.itemId && this.getEvaluate()
      },
    }
  },
  methods: {
    async getEvaluate(){
      this.loading = true
      
      setTimeout(() => {
        this.formData = {
          userName: '账号昵称',
          goodsName: '苹果笔记本',
          content: '我是内容',
          contentImages: ['https://hq-one-stand.oss-cn-shenzhen.aliyuncs.com/coox/category/1669185217450.jpg','https://hq-one-stand.oss-cn-shenzhen.aliyuncs.com/coox/category/1669185217450.jpg'],
          approve: '2'
        }
        this.loading = false
      }, 1000)
    },
    handleClose(){
      this.$emit('update:visible', false)
      this.formData = {}
      this.$refs.form.resetFields()
    },
  },
  filters: {
    filterStatus(val){
      if(!val) return null
      if(val == '1'){
        return '待审核'
      }else if(val == '2'){
        return '已通过'
      }else {
        return '不通过'
      }
    }
  },
}
</script>

<style lang="scss" scoped>

</style>