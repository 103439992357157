<template>
  <el-dialog 
    title="审核"
    :visible="visible"
    :close-on-click-modal="true"
    :before-close="handleClose"
    width="600px"
  >
    <gl-wrap right="30px">
      <el-form :model="formData" ref="form" label-width="120px" size="mini" v-loading="loading">
        <el-form-item prop="approval" label="审核" verify>
          <!-- <gl-option v-model="formData.approval" :list="approveList"></gl-option> -->
          <el-radio-group v-model="formData.approval">
            <el-radio v-for="(item,index) of approveList" :key="index" :label="item.value">{{item.name}}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item prop="reason" label="不通过原因" verify>
          <el-input v-model="formData.reason" placeholder="请输入不通过原因"></el-input>
        </el-form-item>
      </el-form> 
    </gl-wrap>
    <gl-flex style="margin-top: 50px;">
      <el-button type="primary" @click="onSubmit" >确认</el-button>
      <el-button @click="handleClose" >关闭</el-button>
    </gl-flex>
  </el-dialog>
</template>

<script>
import base64 from '@/utils/base64'
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    itemId: {
      type: String,
      default: '',
    },
  },
  data(){
    return{
      oldList: [],
      formData: {},
      loading: false,
      approveList: [
        // {name: '待审核', value: '1'},
        {name: '已通过', value: '2'},
        {name: '不通过', value: '3'},
      ]
    }
  },
  methods: {
    onSubmit(){
      this.$refs.form.validate(async valid => {
        console.log('formData:', this.formData)
        if(!valid) return false
        this.loading = true
        
        this.loading = false
        this.$message.success('操作成功')
        this.$emit('reload')
        this.handleClose()
      })
    },
    handleClose(){
      this.$emit('update:visible', false)
      this.formData = {}
      this.$refs.form.resetFields()
      this.loading = false
    },
  },
}
</script>

<style>

</style>